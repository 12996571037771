import "./App.css";

import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator, View } from "@aws-amplify/ui-react";

import { useAppSelector } from "./hooks";
import { TOOL } from "./store/userSelection";

import AppBar from "./components/AppBar";
import RawProfile from "./components/RawProfile";
import About from "./components/About";
import ProfileIndex from "./components/ProfileIndex";
import ProfileIndexStats from "./components/ProfileIndexStats";
import Etl from "./components/Etl";
import Billing from "./components/Billing";
import Exports from "./components/Exports";
import Unify from "./components/Unify";
import Validate from "./components/Validate";
import TransformRecords from "./components/TransformRecords";
import PlanBulkAction from "./components/PlanBulkAction";

function App({ signOut }: Record<string, any>) {
    const selectedTool = useAppSelector(state => state.userSelection.tool);
    return (
        <View className="App">
            <AppBar signOut={signOut} />
            {selectedTool === TOOL.ABOUT && <About />}
            {selectedTool === TOOL.PROFILE_INDEX && <ProfileIndex />}
            {selectedTool === TOOL.INDEX_STATS && <ProfileIndexStats />}
            {selectedTool === TOOL.PROFILE && <RawProfile />}
            {/* {selectedTool === TOOL.ETL && <Etl />} */}
            {selectedTool === TOOL.BILLING && <Billing />}
            {selectedTool === TOOL.BILLING && <Billing />}
            {selectedTool === TOOL.EXPORTS && <Exports />}
            {selectedTool === TOOL.UNIFY && <Unify />}
            {selectedTool === TOOL.TRANSFORM && <TransformRecords />}
            {selectedTool === TOOL.VALIDATE && <Validate />}
            {/* {selectedTool === TOOL.PLAN_BULK_ACTION && <PlanBulkAction />} */}
        </View>
    );
}

export default withAuthenticator(App);
